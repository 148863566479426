import React from "react";

import ErrorPage from "../sections/ErrorPage/ErrorPage";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <>
    <SEO title="Page not found" />
    <ErrorPage
        errorName="That page wasn't found.">
        A 404 error was encountered while trying to look for that page. Check
        the URL to ensure the path is correct.
    </ErrorPage>
  </>
);

export default NotFoundPage;
