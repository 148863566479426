import React from "react";
import { Link } from "gatsby";
import "./ActionLink.scss";

const ActionLink = ({ children, ...props }) =>  (
    <Link className="action-link" {...props}>
        {children}
    </Link>
);

export default ActionLink;
