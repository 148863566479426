import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";

import ActionLink from "../../components/ActionLink/ActionLink";
import Section from "../../components/Section/Section";
import "./ErrorPage.scss";

const ErrorPage = ({ errorName, children }) => {
    const data = useStaticQuery(graphql`
        query {
          file(relativePath: {eq: "sadshoob.png"}) {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
    `);

    return (
        <Section className="error-page">
            <div className="error-page__image">
                <Img className="error-page__imageNode" fluid={data.file.childImageSharp.fluid} alt="Sad shoob 😞" />
            </div>
            <div className="error-page__message">
                <h1>Heck. { errorName } 😔</h1>
                <p className="color-page__description">{ children }</p>
                <ActionLink to="/">Return home</ActionLink>
            </div>
        </Section>
    );
};

export default ErrorPage;
